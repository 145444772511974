import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Step from "../Step";
import "./Settings.css";
import readXlsxFile from "read-excel-file";
import { addDocumentData, getDocument } from "../../firebase";
import moment from "moment";
import { determinStatus, determinStatusClass, exportToSpreadsheet } from "../../Utils";
import { validate } from "uuid";

const Timing = ({ handleChange, currentName, checked, isDisabled }) => {
  useEffect(() => {
    let elems = document.getElementsByName(currentName);
    elems.forEach((el) => {
      if (el.value === checked) {
        el.click();
      }
    });
  }, [checked]);
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center align-content-center" onChange={(e) => handleChange(e.target.value)}>
      <div className="fs-3 text-muted">למי לשלוח?</div>

      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center align-content-center">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <span className="">לכולם</span>
          <input disabled={isDisabled} class="form-check-input p-2" type="radio" name={currentName} value="all" />
        </div>
        <div className="col-3 d-flex flex-column justify-content-center align-items-center">
          <span className="">רק למי שלא מגיע</span>
          <input disabled={isDisabled} class="form-check-input p-2" type="radio" name={currentName} value="notComing" />
        </div>{" "}
        <div className="col-3 d-flex flex-column justify-content-center align-items-center">
          <span className="">מוזמנים שאישרו</span>
          <input disabled={isDisabled} class="form-check-input p-2" type="radio" name={currentName} value="approved" />
        </div>{" "}
        <div className="col-3 d-flex flex-column justify-content-center align-items-center">
          <span className="">שלא הגיבו</span>
          <input disabled={isDisabled} class="form-check-input p-2" type="radio" name={currentName} value="pending" />
        </div>{" "}
      </div>
    </div>
  );
};
const Settings = ({ addAlertText, docID }) => {
  const [rawData, setRawData] = useState([]);
  const [uploadOk, setUploadOk] = useState(null);
  const [amount, setAmount] = useState(null);
  const [contactList, setContactList] = useState([]);

  const [firstSmsDate, setFirstSmsDate] = useState(new Date());
  const [secondSmsDate, setSecondSmsDate] = useState("");
  const [thirdSmsDate, setThirdSmsDate] = useState("");
  const [forSmsDate, setForSmsDate] = useState("");
  const [fifthSmsDate, setFifthSmsDate] = useState("");
  const [firstSmsMessage, setFirstSmsMessage] = useState("");
  const [secondSmsMessage, setSecondSmsMessage] = useState("");
  const [thirdSmsMessage, setThirdSmsMessage] = useState("");
  const [forSmsMessage, setForSmsMessage] = useState("");
  const [fifthSmsMessage, setFifthSmsMessage] = useState("");
  const [firstSmsLink, setFirstSmsLink] = useState(false);
  const [secondSmsLink, setSecondSmsLink] = useState(false);
  const [thirdSmsLink, setThirdSmsLink] = useState(false);
  const [forSmsLink, setForSmsLink] = useState(false);
  const [fifthSmsLink, setFifthSmsLink] = useState(false);
  const [firstSmsAfter, setFirstSmsAfter] = useState(false);
  const [secondSmsAfter, setSecondSmsAfter] = useState(false);
  const [thirdSmsAfter, setThirdSmsAfter] = useState(false);
  const [forSmsAfter, setForSmsAfter] = useState(false);
  const [fifthSmsAfter, setFifthSmsAfter] = useState(false);
  const [submitSms, setSubmitSms] = useState(false);
  const [firstSmsSendTo, setFirstSmsSendTo] = useState("all");
  const [SecondSmsSendTo, setSecondSmsSendTo] = useState("all");
  const [ThirdSmsSendTo, setThirdSmsSendTo] = useState("all");
  const [ForSmsSendTo, setForSmsSendTo] = useState("all");
  const [FifthSmsSendTo, setFifthSmsSendTo] = useState("all");
  const [format, setFormat] = useState("dd-MM-yyyy HH:mm ");
  const [total, setTotal] = useState("");
  const [clickCounter, setClickCounter] = useState(0);
  const [smsBank, setSmsBank] = useState(0);
  useEffect(() => {
    let elems = document.querySelectorAll(".react-datetime-picker__inputGroup");
    elems.forEach((el) => {
      el.classList.add("d-flex", "flex-row-reverse", "justify-content-center", "align-items-center");
    });
    let moreElems = document.querySelectorAll(".react-datetime-picker__inputGroup__year");
    moreElems.forEach((el) => {
      el.max = "2035";
      el.min = "2022";
    });
  }, []);
  useEffect(() => {

    if (docID) {
      console.log(docID);
      getDocument({ docID: docID }).then((res) => {
        let rawData = res.data();
        setSmsBank(rawData.smsBank);
        if (rawData.hasOwnProperty("contacts")) {
          setContactList(rawData.contacts);
        }
        if (rawData.hasOwnProperty("messages")) {
          let data = rawData.messages;
          console.log(data);
          data.forEach((el, idx) => {
            if (idx === 0) {
              console.log("idx === 0");
              setFirstSmsLink(el.withLink);
              setFirstSmsMessage(el.message);
              setFirstSmsDate(new Date(el.timeToSend));
              setFirstSmsSendTo(el.toGroup);
              let momentTime = moment(el.timeToSend);
              if (moment(new Date()).isAfter(momentTime)) {
                setFirstSmsAfter(true);
              }
            }
            if (idx === 1) {
              setSecondSmsLink(el.withLink);
              setSecondSmsMessage(el.message);
              setSecondSmsDate(new Date(el.timeToSend));
              setSecondSmsSendTo(el.toGroup);
              let momentTime = moment(el.timeToSend);
              if (moment(new Date()).isAfter(momentTime)) {
                setSecondSmsAfter(true);
              }
            }
            if (idx === 2) {
              setThirdSmsLink(el.withLink);
              setThirdSmsMessage(el.message);
              setThirdSmsDate(new Date(el.timeToSend));
              setThirdSmsSendTo(el.toGroup);
              let momentTime = moment(el.timeToSend);
              if (moment(new Date()).isAfter(momentTime)) {
                setThirdSmsAfter(true);
              }
            }
            if (idx === 3) {
              setForSmsLink(el.withLink);
              setForSmsMessage(el.message);
              setForSmsDate(new Date(el.timeToSend));
              setForSmsSendTo(el.toGroup);

              let momentTime = moment(el.timeToSend);
              if (moment(new Date()).isAfter(momentTime)) {
                setForSmsAfter(true);
              }
            }
            if (idx === 4) {
              setFifthSmsLink(el.withLink);
              setFifthSmsMessage(el.message);
              setFifthSmsDate(new Date(el.timeToSend));
              setFifthSmsSendTo(el.toGroup);

              let momentTime = moment(el.timeToSend);
              if (moment(new Date()).isAfter(momentTime)) {
                setFifthSmsAfter(true);
              }
            }
          });
        }
      });
    }
    let uploader = document.querySelector(".uploader");
    uploader.addEventListener("input", async (e) => {
      const file = e.target.files[0];
      let { name } = file;
      let splitted = name.split(".");
      let fileType = splitted[splitted.length - 1];
      if (fileType !== "xlsx") {
        addAlertText({ mode: "danger", text: "יש להעלות קובץ מסוג אקסל בלבד" });
      }
      readXlsxFile(e.target.files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        setRawData(rows);
      });
    });
    return () => {
      uploader.removeEventListener("change", () => {});
    };
  }, [docID]);
  useEffect(() => {
    if (contactList.length > 0) {
      setTotal(contactList.length);
    }
  }, [contactList]);
  useEffect(() => {
    let temp = [];
    let errors = 0;
    if (!rawData.length == 0) {
      rawData.forEach((el) => {
        console.log(el);

        if (el[1] === "מספר טלפון" || el[0] === "שם מלא") {
          return;
        }
        if (!el[0] || !el[1]) {
          console.log(el[1]);
          errors += 1;
          return;
        }
        if (el[1]) {
          var phoneNumber = `${el[1]}`;
          let splittedNum = phoneNumber.split("-");
          if (splittedNum.length > 1) {
            // with -
            // console.log("splittedNum", splittedNum);
            phoneNumber = splittedNum.join("");
            console.log("after split ", phoneNumber);
          }
        }
        if (el[0] && el[1]) {
          let payload = {
            name: el[0],
            phoneNumber: phoneNumber,
            status: "pending",
            needsRide: false,
            gift: "0",
            howMany: "1",
            dedication: "",
            tableNum: "",
          };
          temp.push(payload);
        }
      });
      console.log(temp);
      if (errors > 2) {
        setUploadOk(false);
        return;
      }
      setAmount(temp.length);
      setTotal(temp.length);
      setUploadOk(true);
      addDocumentData({ docID: docID, payload: { contacts: temp } });
    }
  }, [rawData]);

  useEffect(() => {
    // contactList.forEach((el)=>{
    //   console.log(el)
    // })

    let res = [];
    var totalReq = 0;
    let format = "YYYY-MM-DD HH:mm";

    if (submitSms) {
      if (firstSmsMessage) {
        res.push({
          timeToSend: moment(firstSmsDate).format(format),
          message: firstSmsMessage,
          toGroup: firstSmsSendTo,
          withLink: firstSmsLink,
        });
        let formatIt = moment(firstSmsDate).format(format);
        let current = moment();
        let toTime = moment(formatIt);
        // **Edit 1:**  Correct comparison of moment objects
        if (current.isAfter(toTime)) {
          // setFifthSmsAfter(true);
          addAlertText({ text: "יש לבחור תאריך עתידי.", mode: "warning" });
          return;
        }
        if (moment(new Date()).isBefore(moment(firstSmsDate))) {
          // setFifthSmsAfter(true);
          let items = contactList.filter((el) => el.status === firstSmsSendTo || firstSmsSendTo === "all");
          totalReq += items.length;
        }
      }
      if (secondSmsMessage && secondSmsDate) {
        res.push({
          timeToSend: moment(secondSmsDate).format(format),
          message: secondSmsMessage,
          toGroup: SecondSmsSendTo,
          withLink: secondSmsLink,
        });
        let formatIt = moment(secondSmsDate).format(format);
        let current = moment();
        let toTime = moment(formatIt);
        // **Edit 2:**  Correct comparison of moment objects
        if (current.isAfter(toTime)) {
          // setFifthSmsAfter(true);
          addAlertText({ text: "יש לבחור תאריך עתידי.", mode: "warning" });
          return;
        }
        if (moment(new Date()).isBefore(moment(secondSmsDate))) {
          // setFifthSmsAfter(true);
          let items = contactList.filter((el) => el.status === SecondSmsSendTo || SecondSmsSendTo === "all");
          totalReq += items.length;
        }
      }

      if (thirdSmsMessage) {
        res.push({
          timeToSend: moment(thirdSmsDate).format(format),
          message: thirdSmsMessage,
          toGroup: ThirdSmsSendTo,
          withLink: thirdSmsLink,
        });
        let formatIt = moment(thirdSmsDate).format(format);
        let current = moment();
        let toTime = moment(formatIt);
        // **Edit 3:**  Correct comparison of moment objects
        if (current.isAfter(toTime)) {
          // setFifthSmsAfter(true);
          addAlertText({ text: "יש לבחור תאריך עתידי.", mode: "warning" });
          return;
        }
        if (moment(new Date()).isBefore(moment(thirdSmsDate))) {
          // setFifthSmsAfter(true);
          let items = contactList.filter((el) => el.status === ThirdSmsSendTo || ThirdSmsSendTo === "all");
          totalReq += items.length;
        }
      }

      if (forSmsMessage && forSmsDate) {
        res.push({
          timeToSend: moment(forSmsDate).format(format),
          message: forSmsMessage,
          toGroup: ForSmsSendTo,
          withLink: forSmsLink,
        });
        let formatIt = moment(forSmsDate).format(format);
        let current = moment();
        let toTime = moment(formatIt);
        // **Edit 4:**  Correct comparison of moment objects
        if (current.isAfter(toTime)) {
          // setFifthSmsAfter(true);
          addAlertText({ text: "יש לבחור תאריך עתידי.", mode: "warning" });
          return;
        }
        if (moment(new Date()).isBefore(moment(forSmsDate))) {
          // setFifthSmsAfter(true);
          let items = contactList.filter((el) => el.status === ForSmsSendTo || ForSmsSendTo === "all");
          totalReq += items.length;
        }
      }

      if (fifthSmsMessage && fifthSmsDate) {
        res.push({
          timeToSend: moment(fifthSmsDate).format(format),
          message: fifthSmsMessage,
          toGroup: FifthSmsSendTo,
          withLink: fifthSmsLink,
        });
        let formatIt = moment(fifthSmsDate).format(format);
        let current = moment();
        let toTime = moment(formatIt);
        // **Edit 5:**  Correct comparison of moment objects
        if (current.isAfter(toTime)) {
          // setFifthSmsAfter(true);
          addAlertText({ text: "יש לבחור תאריך עתידי.", mode: "warning" });
          return;
        }
        if (moment(new Date()).isBefore(moment(fifthSmsDate))) {
          let items = contactList.filter((el) => el.status === FifthSmsSendTo || FifthSmsSendTo === "all");
          totalReq += items.length;
        }
      }

      console.log(totalReq);
      if (parseInt(totalReq) > parseInt(smsBank)) {
        addAlertText({ text: "כמות ההודעות נמוכה מדי, יש לרכוש קרדיט.", mode: "warning" });
        return;
      }
      let payload = {
        messages: res,
      };

      const fire = async () => {
        console.log(docID);
        let res = await addDocumentData({ docID: docID, payload: payload });
        addAlertText({ text: "השינויים נשמרו בהצלחה.", mode: "success" });
      };
      if (firstSmsMessage || secondSmsMessage || thirdSmsMessage || forSmsMessage || fifthSmsMessage) {
        fire();
        setSubmitSms(false);
        return;
      } else {
        setSubmitSms(false);

        addAlertText({ text: "יש למלא הודעה אחת לפחות", mode: "warning" });
      }
    }
  }, [submitSms]);

  const UploadBar = ({ status, amount }) => {
    if (status == null) {
      return null;
    }
    return (
      <div className="col-12 d-flex flex-row justify-content-center align-content-center align-items-center m-3">
        <img src={status ? "./assets/icons/check.svg" : "./assets/icons/out.svg"} height="30" width="30" />
        <div className="m-2">{status ? `אנשי הקשר הוספו בהצלחה. נוספו ${amount} מוזמנים לאירוע.` : "הקובץ פגום, ניתן להסתכל בקובץ לדוגמא."}</div>
      </div>
    );
  };
  const onFullClick = () => {
    if (total > 0 && clickCounter == 0) {
      alert("העלאת קובץ חדש תמחק את רשימת המוזמנים הקודמת.");
      setClickCounter(1);
    }
  };

  const handleDateChange = (value, func) => {
    let format = "YYYY-MM-DD HH:mm";
    console.log("value", value.detail);
    console.log("value", value);

    let formatIt = moment(value).format(format);
    let current = moment();
    let toTime = moment(formatIt);
    // console.log(toTime);
    // console.log(current);
    // console.log(toTime.isValid());

    if (toTime.isValid()) {
      let newTime = new Date(formatIt);
      console.log("newTime", newTime);

      func(newTime);
      return;
    }
  };
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div className="col-10 d-flex flex-column justify-content-center align-items-center  mt-2 position-relative border-top toHide" onClick={onFullClick}>
        <Step num={1} />
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-column uploadBox justify-content-center align-items-center fs-5 greyBlueText shadow-sm rounded-3 bg-light toHide">
          לחצו כאן להעלות את קובץ רשימת האורחים
          <div className="p-2 fs-6 col-6 text-danger">שימו לב,העלאת קובץ חדש תמחוק את כל רשימת המוזמנים והנתונים שנאספו במערכת ללא יכולת שחזור.</div>
          <a href="./assets/files/אקסל מוזמנים לדוגמא.xlsx" className="fs-6">
            לחצו כאן על מנת להוריד קובץ לדוגמא
          </a>
          <img src="./assets/icons/upload.svg" className="" height="50" width="50"></img>
          <input className="form-control col-10 uploader" type="file" placeholder="בחר קובץ" />
        </div>
        <UploadBar amount={amount} status={uploadOk} />
      </div>
      {total && (
        <div className="m-2 border border-success p-2 rounded pointer" onClick={() => exportToSpreadsheet(contactList, "רשימת מוזמנים")}>
          {" "}
          סך הכל {total} אנשי קשר הועלו (לחצו להורדת הקובץ)
        </div>
      )}

      <div className="col-10 d-flex flex-column justify-content-center align-items-center border-top mt-3 toHide">
        <Step num={2} />
        <div className="fs-3 text-muted">תזכורות לאורחים</div>
        <div className="w-75">
          משתמשים יקרים, על מנת למנוע טעויות, לאחר שתסיימו להזין את התאריכים וההודעות שתרצו שהמוזמנים שלכם יקבלו, יש ללחוץ על הכפתור מתחת.
        </div>
        <div className="btn btn-lg btn-success mb-5" onClick={() => setSubmitSms((prev) => !prev)}>
          לחצו כאן לשמירה
        </div>
        <div className="col-12 d-flex flex-column shadow-sm justify-content-center align-items-center">
          <span className="fs-3 text-center">בנק SMS</span>
          <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
            <span className="fs-5 text-muted m-1">נותרו</span>
            <span className="fs-5 text-muted m-1">{smsBank}</span>
            <span className="fs-5 text-muted m-1">הודעות לשליחה</span>
          </div>
          <a
            onClick={() =>
              window.open("https://www.flashback.co.il/he/%D7%94%D7%96%D7%9E%D7%A0%D7%94-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C%D7%99%D7%AA/buy-sms", "_blank")
            }
            className="btn btn-lg border border-success"
            style={{ fontSize: "15px" }}
          >
            לרכישת הודעות SMS
          </a>
        </div>
        <div
          className={`col-12 d-flex flex-column border-${
            firstSmsAfter ? "success" : "light"
          } justify-content-center align-items-center shadow-lg m-2 bg-light rounded border border-3`}
        >
          <div className="fs-5">סבב תזכורות ראשון</div>

          <Timing
            isDisabled={firstSmsAfter}
            currentName={"smdkslmolsikmmdoisdsdsdsdinsiondiosnd"}
            key="ksakioljhasiojioasjioajsiojasiojioasjioajas"
            handleChange={setFirstSmsSendTo}
            checked={firstSmsSendTo}
          />
          <div className="col d-flex flex-row justify-content-center align-items-center m-3">
            <input
              class="form-check-input m-1"
              type="checkbox"
              checked={firstSmsLink ? true : false}
              value=""
              onChange={() => setFirstSmsLink((prev) => !prev)}
              disabled={firstSmsAfter}
            />
            <span className="m-1">צרף קישור להזמנה הדיגטלית</span>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center customPicker">
            <img src="./assets/icons/date.svg" className="m-2" height="35" width="35" />
            <DateTimePicker
              key="slkndjdfdfsnidnsiondiosnionsino"
              locale="he"
              amPmAriaLabel={true}
              calendarClassName={"cali"}
              className={"bg-white  form-control"}
              format={format}
              value={firstSmsDate}
              onChange={(e) => handleDateChange(e, setFirstSmsDate)}
              disabled={firstSmsAfter}
              autoFocus={false}
              // calendarIcon={true}
              minDate={new Date()}
            />
          </div>
          <textarea
            className="col-xxl-10 col-xl-10 col-11 m-3 p-2"
            style={{ minHeight: "100px" }}
            maxLength="170"
            placeholder={`רישמו את ההודעה שלכם  - כאן מומלץ לשלוח סבב הודעות ראשון לכולם.
            לדוגמא : מיכאל ודיקלה מתחתנים בתאריך 25.4.2024 בחוות הצוק, כרמי יוסף. אתם מוזמנים לחגוג איתנו. אנא אשרו את הגעתכם`}
            value={firstSmsMessage}
            onChange={(e) => setFirstSmsMessage(e.target.value)}
            disabled={firstSmsAfter}
          >
            {firstSmsMessage}
          </textarea>
        </div>
        <div
          className={`col-12 d-flex flex-column border-${
            secondSmsAfter ? "success" : "light"
          } justify-content-center align-items-center shadow-lg m-2 bg-light rounded border border-3`}
        >
          <div className="fs-5"> תזכורות לפני האירוע</div>

          <Timing
            isDisabled={secondSmsAfter}
            currentName={"smdkslmolsikmmdoisddssdssdinsiondiosnd"}
            key="ksakioljhasiojioasgfgffgfgxgxfjioajsiojasiojioasjioajas"
            handleChange={setSecondSmsSendTo}
            checked={SecondSmsSendTo}
          />
          <div className="col d-flex flex-row justify-content-center align-items-center m-3">
            <input
              class="form-check-input m-1"
              type="checkbox"
              value=""
              checked={secondSmsLink ? true : false}
              onChange={() => setSecondSmsLink((prev) => !prev)}
              disabled={secondSmsAfter}
            />
            <span className="m-1">צרף קישור להזמנה הדיגטלית</span>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center customPicker">
            <img src="./assets/icons/date.svg" className="m-2" height="35" width="35" />
            <DateTimePicker
              autoFocus={false}
              key="slkndjsnidnsiondioscxcxdfdffddfdfdnionsino"
              locale="he"
              amPmAriaLabel={true}
              
              calendarClassName={"form-control"}
              calendarIcon={true}
              className={"bg-white  form-control"}
              minDate={new Date()}
              format={format}
              value={secondSmsDate}
              onChange={(e) => handleDateChange(e, setSecondSmsDate)}
              disabled={secondSmsAfter}
            />
          </div>
          <textarea
            className="col-xxl-10 col-xl-10 col-11 m-3 p-2"
            style={{ minHeight: "100px" }}
            maxLength="170"
            value={secondSmsMessage}
            placeholder={`רישמו את ההודעה שלכם – כאן מומלץ לשלוח תזכורות למי שלא ענה על הסבב הראשון. 
            לדוגמא:פססט.. עדיין לא שמענו מכם. מיכאל ודיקלה מתחתנים בתאריך 25.4.2024 בחוות הצוק, כרמי יוסף. אתם מוזמנים לחגוג איתנו. אנא אשרו את הגעתכם`}
            onChange={(e) => setSecondSmsMessage(e.target.value)}
            disabled={secondSmsAfter}
          >
            {secondSmsMessage}
          </textarea>
        </div>
        <div
          className={`col-12 d-flex flex-column border-${
            thirdSmsAfter ? "success" : "light"
          } justify-content-center align-items-center shadow-lg m-2 bg-light rounded border border-3`}
        >
          <div className="fs-5">תזכורת לפני האירוע </div>

          <Timing
            isDisabled={thirdSmsAfter}
            currentName={"smdkslmolsikmmdoisdsdsdinsiondiosnd"}
            key="ksakioljhasioxgfgffgxfgjioasjioajsiojasiojioasjioajas"
            handleChange={setThirdSmsSendTo}
            checked={ThirdSmsSendTo}
          />
          <div className="col d-flex flex-row justify-content-center align-items-center m-3">
            <input
              class="form-check-input m-1"
              type="checkbox"
              value=""
              checked={thirdSmsLink ? true : false}
              onChange={() => setThirdSmsLink((prev) => !prev)}
              disabled={thirdSmsAfter}
            />
            <span className="m-1">צרף קישור להזמנה הדיגטלית</span>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center customPicker">
            <img src="./assets/icons/date.svg" className="m-2" height="35" width="35" />
            <DateTimePicker
              autoFocus={false}
              key="slkndjsnidnsiondfdfdfdfdffddiosnionsino"
              locale="he"
              amPmAriaLabel={true}
              
              calendarClassName={"form-control"}
              calendarIcon={true}
              className={"bg-white  form-control"}
              minDate={new Date()}
              format={format}
              value={thirdSmsDate}
              onChange={(e) => handleDateChange(e, setThirdSmsDate)}
              disabled={thirdSmsAfter}
            />
          </div>
          <textarea
            className="col-xxl-10 col-xl-10 col-11 m-3 p-2"
            style={{ minHeight: "100px" }}
            maxLength="170"
            value={thirdSmsMessage}
            placeholder={`רישמו את ההודעה שלכם - כאן מומלץ לשלוח תזכורות לאירוע כולל תאריך ושעה לכל מי  שאישר הגעה.
            לדוגמא: רק רצינו להזכיר לכם את האירוע שלנו,שלא תפספסו
          מיכאל ודיקלה מתחתנים בתאריך 25.4.2024 בחוות הצוק, כרמי יוסף. אתם מוזמנים לחגוג איתנו. אנא אשרו את הגעתכם`}
            onChange={(e) => setThirdSmsMessage(e.target.value)}
            disabled={thirdSmsAfter}
          >
            {thirdSmsMessage}
          </textarea>
        </div>
        <div
          className={`col-12 d-flex flex-column border-${
            forSmsAfter ? "success" : "light"
          } justify-content-center align-items-center shadow-lg m-2 bg-light rounded border border-3`}
        >
          <div className="fs-5"> סבב נוסף</div>

          <Timing
            isDisabled={forSmsAfter}
            currentName={"smdkslmolsikmsdsdsdmdoisdinsiondiosnd"}
            key="ksaxgfffggfxkioljhasiojioasjioajsiojasiojioasjioajas"
            handleChange={setForSmsSendTo}
            checked={ForSmsSendTo}
          />
          <div className="col d-flex flex-row justify-content-center align-items-center m-3">
            <input
              class="form-check-input m-1"
              type="checkbox"
              value=""
              checked={forSmsLink ? true : false}
              onChange={() => setForSmsLink((prev) => !prev)}
              disabled={forSmsAfter}
            />
            <span className="m-1">צרף קישור להזמנה הדיגטלית</span>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center customPicker">
            <img src="./assets/icons/date.svg" className="m-2" height="35" width="35" />
            <DateTimePicker
              autoFocus={false}
              key="slkndjsnidndfdfdfdfdfdfsiondiosnionsino"
              locale="he"
              amPmAriaLabel={true}
              
              calendarClassName={"form-control"}
              calendarIcon={true}
              className={"bg-white  form-control"}
              minDate={new Date()}
              format={format}
              value={forSmsDate}
              onChange={(e) => handleDateChange(e, setForSmsDate)}
              disabled={forSmsAfter}
            />
          </div>
          <textarea
            className="col-xxl-10 col-xl-10 col-11 m-3 p-2"
            style={{ minHeight: "100px" }}
            maxLength="170"
            placeholder={`רישמו את ההודעה שלכם - כאן אפשר לשלוח תודות לכל מי שאישר הגעה
            לדוגמא: איזה כיף היה! אנחנו עדיין בהיי מטורף! רצינו להודות לכם מכל הלב שהשתתפתם בשמחה שלנו, מאחלים לכולם שנמשיך לחגוג ביחד`}
            onChange={(e) => setForSmsMessage(e.target.value)}
            value={forSmsMessage}
            disabled={forSmsAfter}
          >
            {forSmsMessage}
          </textarea>
        </div>
        <div
          className={`col-12 d-flex flex-column border-${
            fifthSmsAfter ? "success" : "light"
          } justify-content-center align-items-center shadow-lg m-2 bg-light rounded border border-3`}
        >
          <div className="fs-5"> סבב נוסף</div>

          <Timing
            isDisabled={fifthSmsAfter}
            currentName={"smdkslmolsikmmdoisdinsiondiosnd"}
            key="ksakioljhasiojioasjioajsiojasiojioasjisffsfsfsffsfoajas"
            handleChange={setFifthSmsSendTo}
            checked={FifthSmsSendTo}
          />
          <div className="col d-flex flex-row justify-content-center align-items-center m-3">
            <input
              class="form-check-input m-1"
              type="checkbox"
              value=""
              checked={fifthSmsLink ? true : false}
              onChange={() => setFifthSmsLink((prev) => !prev)}
              disabled={fifthSmsAfter}
            />
            <span className="m-1">צרף קישור להזמנה הדיגטלית</span>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center customPicker">
            <img src="./assets/icons/date.svg" className="m-2" height="35" width="35" />
            <DateTimePicker
              autoFocus={false}
              key="slkndjsnidnfdfddffddfdfsiondiosnionsino"
              locale="he"
              amPmAriaLabel={true}
              
              calendarClassName={"form-control"}
              calendarIcon={true}
              className={"bg-white  form-control"}
              minDate={new Date()}
              format={format}
              value={fifthSmsDate}
              onChange={(e) => setFifthSmsDate(e)}
              disabled={fifthSmsAfter}
            />
          </div>
          <textarea
            className="col-xxl-10 col-xl-10 col-11 m-3 p-2"
            style={{ minHeight: "100px" }}
            maxLength="170"
            placeholder="רישמו את ההודעה שלכם - אפשרות לעוד סבב הודעות אם צריך"
            onBlur={(e) => handleDateChange(e, setFifthSmsMessage)}
            value={fifthSmsMessage}
            disabled={fifthSmsAfter}
          >
            {fifthSmsMessage}
          </textarea>
        </div>
      </div>
    </div>
  );
};

export default Settings;
